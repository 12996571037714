
import { ClientObject } from '../_models/client';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { ccworksheetsObject } from '../_models/ccworksheets';


@Injectable()
export class SevenDaysNoticeService {

  private _Url: string = "/api/sevendaysnotice/";
  private _genericUrl: string = "/api/generic/";
  private _controllerName: string = "sevendaysnotice";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {

  }
  

  createSevenDaysNotice(inception_date: string, as_at_date: string, directdebit: string) {
    return this.authHttp.get<any>(this._Url + "CreateSevenDaysNotice/" + inception_date + "/" + as_at_date + "/" + directdebit + "/");
  }

  updateValues(agentGroupCode: string, wsheet: string, inception_date: string, part:number) {
    return this.authHttp.get<any>(this._Url + "UpdateValues/" + agentGroupCode + "/" + wsheet + "/" + inception_date + "/" + part + "/");
  }

  updateValuesAgents(wsheet: string, inception_date: string, part: number) {
    return this.authHttp.get<any>(this._Url + "UpdateValuesAgents/" + wsheet + "/" + inception_date + "/" + part + "/");
  }

  getAllSevenDaysUpdated(wsheet: string) {
    return this.authHttp.get<any>(this._Url + "GetAllSevenDaysUpdated/" + wsheet + "/");
  }

  getAllSevenDaysUpdatedUnderwriting(wsheet: string) {
    return this.authHttp.get<any>(this._Url + "GetAllSevenDaysUpdatedUnderwriting/" + wsheet + "/");
  }

  getTaskSchedule(wsheet: string) {
    return this.authHttp.get<any>(this._Url + "GetTaskSchedule/" + wsheet + "/");
  }

  sendEmail(wsheet: string) {
    return this.authHttp.get<any>(this._Url + "SendEmail/" + wsheet + "/");
  }

  sendEmailToAgents(wsheet: string) {
    return this.authHttp.get<any>(this._Url + "SendEmailToAgents/" + wsheet + "/");
  }
  
  sendEmailReminder(wsheet: string) {
    return this.authHttp.get<any>(this._Url + "SendEmailReminder/" + wsheet + "/");
  }

  sendEmailAutomation(wsheet: string) {
    return this.authHttp.get<any>(this._Url + "SendEmailAutomation/" + wsheet + "/");
  }

  sendEmailReminderToSpecificAgent(agent_group_code: string, wsheet: string) {
    return this.authHttp.get<any>(this._Url + "SendEmailReminderToSpecificAgent/" + agent_group_code + "/" + wsheet + "/");
  }

  // ---------------------------------------------------------------------------------------- //

  // Agents
  getWorksheetsByRunCode(run_Code : string) {
    return this.authHttp.get<any>(this._Url + "GetWorksheetsByRunCode/" + run_Code + "/");
  }

  // Agents
  getAllWorksheets() {
    return this.authHttp.get<any>(this._Url + "GetAllWorksheets/");
  }

  // Accounting
  getAllWorksheetsAccounting() {
    return this.authHttp.get<any>(this._Url + "GetAllWorksheetsAccounting/");
  }

  // Approve
  getAllWorksheetsApprove() {
    return this.authHttp.get<any>(this._Url + "GetAllWorksheetsApprove/");
  }

  // Agents - History
  getAllWorksheetsHistoryAgents() {
    return this.authHttp.get<any>(this._Url + "GetAllWorksheetsHistoryAgents/");
  }

  // Accounting - History
  getAllWorksheetsHistoryAccounting() {
    return this.authHttp.get<any>(this._Url + "GetAllWorksheetsHistoryAccounting/");
  }
  
  // ---------------------------------------------------------------------------------------- //

  getAllPendingSevenDaysAgents(wsheet: string) {
    return this.authHttp.get<any>(this._Url + "GetAllPendingSevenDaysAgents/" + wsheet + "/");
  }


  getAllPendingSevenDaysAccounting(wsheet: string) {
    return this.authHttp.get<any>(this._Url + "GetAllPendingSevenDaysAccounting/" + wsheet + "/");
  }


  getAllPendingSevenDaysApprove(wsheet: string) {
    return this.authHttp.get<any>(this._Url + "GetAllPendingSevenDaysApprove/" + wsheet + "/");
  } 


  updateCCWorksheetsFromAgents(aEntityObject: object) {
    return this.authHttp.post<any>(this._Url + 'SynthesisUpdateCCWorksheets/', aEntityObject);
  } 


  updateCCWorksheetsFromAccounting(aEntityObject: object) {
    return this.authHttp.post<any>(this._Url + 'SynthesisUpdateCCWorksheetsAccounting/', aEntityObject);
  } 

  // Approve or Reject
  approveCCWorksheets(aEntityObject: object) {
    return this.authHttp.post<any>(this._Url + 'SynthesisUpdateCCWorksheetsApprove/', aEntityObject);
  }


  rejectionCCWorksheets(aEntityObject: object) {
    return this.authHttp.post<any>(this._Url + 'RejectPendingEntriesToCCworksheets/', aEntityObject);
  }

  // -----------------------------

  submitToApprove(wsheet: string, id: number) {
    return this.authHttp.get<any>(this._Url + "SubmitToApprove/" + wsheet + "/" + id + "/");
  }

  step2(wsheet: string, id: number) {
    return this.authHttp.get<any>(this._Url + "Step2/" + wsheet + "/" + id + "/");
  }

  step3(wsheet: string, id: number) {
    return this.authHttp.get<any>(this._Url + "Step3/" + wsheet + "/" + id + "/");
  }

  submitToSevenDaysNotice(wsheet: string, id: number) {
    return this.authHttp.get<any>(this._Url + "SubmitToSevenDaysNotice/" + wsheet + "/" + id + "/");
  }

  // ------------------------------

  getAllSevenDaysAgents(wsheet: string) {
    return this.authHttp.get<any>(this._Url + "GetAllSevenDaysAgents/" + wsheet + "/");
  }

  getAllSevenDaysAccounting(wsheet: string) {
    return this.authHttp.get<any>(this._Url + "GetAllSevenDaysAccounting/" + wsheet + "/");
  }

  updateSevenDaysNoticeList(wsheet: string) {
    return this.authHttp.get<any>(this._Url + "UpdateSevenDaysNoticeList/" + wsheet + "/");
  }

  updateSevenDaysNoticeListAgents(wsheet: string) {
    return this.authHttp.get<any>(this._Url + "UpdateSevenDaysNoticeListAgents/" + wsheet + "/");
  }


  getFundBalance() {
    return this.authHttp.get<any>(this._Url + "GetFundBalance/");
  }

  sevenDaysNoticeNotProceedYet() {
    return this.authHttp.get<any>(this._Url + "SevenDaysNoticeNotProceedYet/");
  }

  sendSMS() {
    return this.authHttp.get<any>(this._Url + "SendSMS/");
  }

  postCancellationPolicies(wsheet: string) {
    return this.authHttp.get<any>(this._Url + "PostCancellationPolicies/" + wsheet + "/");
  }

  checkCancellationPoliciesValidations(wsheet: string) {
    return this.authHttp.get<any>(this._Url + "CheckCancellationPoliciesValidations/" + wsheet + "/");
  }

  checkToCreateWorksheet() {
    return this.authHttp.get<any>(this._Url + "CheckToCreateWorksheet/");
  }

}
