import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthenticationService, NotificationsService, ToasterType, ToasterPosition, IdleService } from '../_services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService, private notificationService: NotificationsService, public idleService: IdleService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return <any>next.handle(request).pipe(catchError(err => {



      if ([401, 403].includes(err.status) && this.authenticationService.currentUserValue) {
        //close all timers and events from idle service
        //this.idleService.StopAllTimersAndEvents();
        console.log('Log out from error.interceptor.ts line 22');
        this.authenticationService.logout();
        return throwError({ status: 401, error: { message: 'Unauthorized Access' } });
        //err.statusText = "Unauthorized Request";
      }

      if ([503].includes(err.status)) {
        //close all timers and events from idle service
        //this.idleService.StopAllTimersAndEvents();
        console.log('Log out from error.interceptor.ts line 31');
        this.authenticationService.logout();
        err.statusText = "Locked Database - Service Unavailable";
        this.notificationService.show(ToasterType.error, "The service is unavailable due to system updates!", ToasterPosition.topCenter, 0);

      }

      if ([417].includes(err.status)) {
        err.statusText = "Unexpected Error Occured";
        this.notificationService.show(ToasterType.error, err.error, ToasterPosition.topCenter, 0);
      }

      var error;

      if (err.statusText != "OK" && err.statusText != "Bad Request") {
        error = err.statusText;
      }
      else {
        error = err.error;
      }

      if (error == "One or more errors occurred. (No connection could be made because the target machine actively refused it.)") {
        //close all timers and events from idle service
        //this.idleService.StopAllTimersAndEvents();
        console.log('Log out from error.interceptor.ts line 54');
        this.authenticationService.logout();
      }
                                        
      //const error = (err && err.error && err.error.message) || err.statusText;

  
      return throwError(error);
    }))




  //  if (err.status === 401) {
  //    // auto logout if 401 response returned from api

  //    if (this.authenticationService.currentUserValue) {
  //      this.authenticationService.logout();
  //      location.reload(true);
  //    }
  //  }
  //  const error = err.error.message || err.statusText;
  //  return throwError(error);
  //}))

  }
}
