import { Injectable, EventEmitter, OnInit, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subscription, timer, throwError } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from '../_models';
import { MatDialog } from '@angular/material';


export interface IUserLogin {
  username: string;
  password: string;
}

//@Injectable({ providedIn: 'root' })
@Injectable()
export class AuthenticationService implements OnInit {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;  
  private get router() { return this._injector.get(Router); }


  constructor(private http: HttpClient,
    private jwtHelper: JwtHelperService,    
    private _injector: Injector,
    private dialogRef: MatDialog
  ) {        
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public ngOnInit(): void {
    if (this.currentUserValue) {            
      this.renew().subscribe();  //MSTEST1310
    }
  }

  public get currentUserValue(): any {    
      return this.currentUserSubject.value;
  }  

  public get getAccessToken(): any {
    return this.currentUserValue.accessToken;
  }

  public get isLoggedIn(): boolean {

    if (this.currentUserSubject.value && !this.isTokenExpired)
      return true;
    else
      return false;
  }

  login(user: IUserLogin) {

    return this.http.post<any>('/api/authentication/login', user)
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));        
        this.currentUserSubject.next(user);              
        return user;

      }));
  }

  logout() {
    //clear idle inactivity timer
    //clearTimeout(this.idleService.inactivityTimer);
    //other



    //close all modals
    this.dialogRef.closeAll();
    //other things
    this.stopRefreshTokenTimer();
    localStorage.removeItem('currentUser');

    this.currentUserSubject.next(null);
    this.router.navigate(['/login']);

  }

  //renew() {               //MSTEST1310
  //  return this.http.get<any>('/api/authentication/renew')
  //    .pipe(map(user => {

  //      if (user && user.accessToken) {
  //        // store user details and jwt token in local storage to keep user logged in between page refreshes
  //        localStorage.setItem('currentUser', JSON.stringify(user));
  //        this.currentUserSubject.next(user);
  //         //console.log('store user details and jwt token in local storage to keep user logged in between page refreshes')
  //        this.startRefreshTokenTimer();
  //        return user;
  //      }
  //    })).pipe(first())
  //    .subscribe(
  //      data => {
  //        if (data && data.accessToken) {

  //        }
  //        else {
  //          //console.log('NO TOKEN FOUND');
  //          this.logout();
  //        }
  //      },
  //      error => {          
  //        //this.logout();
  //      });
  //  // });
  //}

  renew() {               //MSTEST1310
    return this.http.get<any>('/api/authentication/renew')
      .pipe(map(user => {

        if (user && user.accessToken) {
     
          // store user details and jwt token in local storage to keep user logged in between page refreshes
   
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);
          //console.log('store user details and jwt token in local storage to keep user logged in between page refreshes')
          this.startRefreshTokenTimer();
          return user;
        }
      }),
        map(data => {
          if (data && data.accessToken) {

          }
          else {
            //console.log('NO TOKEN FOUND');
            this.logout();
          }
        }));
      
  }


  private refreshTokenTimeout;

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    var user = JSON.parse(localStorage.getItem('currentUser'));
    // set a timeout to refresh the token a minute before it expires
    const expires = this.jwtHelper.getTokenExpirationDate(user.accessToken);
    //console.log(expires.getTime());
    //console.log(Date.now());
    
    //const timeout = expires.getTime() - Date.now() - (60 * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);

    //timeout = 1;

    this.refreshTokenTimeout = setTimeout(() => {

      console.log('RENEW TOKEN');
      //this.renew()  //MSTEST1310      
      this.renew().subscribe();//MSTEST1310
    }, timeout);
  }

  private stopRefreshTokenTimer() {
    if (this.refreshTokenTimeout !== null) {
      clearTimeout(this.refreshTokenTimeout);
      clearInterval(this.refreshTokenTimeout);
      this.refreshTokenTimeout = null; // Make sure to reset the reference to null
      console.log('Timeout cleared');
    } else {
      console.log('No timeout to clear');
    }
    //clearTimeout(this.refreshTokenTimeout);
  }

  public get isTokenExpired(): boolean {
    var user = JSON.parse(localStorage.getItem('currentUser'));
    if (user && user.accessToken) {
 
      const isExpired = this.jwtHelper.isTokenExpired(user.accessToken);
      return isExpired;
    } else {
      return true;
    }


  }

}
