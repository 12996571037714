
export class JCCDepositObject {
  JCCDepositID: number = 0;
  MerchantID: number = 0;
  AcquirerID: number = 0;
  OrderID: string = "";
  Amount: number = 0;
  Status: string = "";
  ResponseCode: number = 0;
  ReasonCode: number = 0;
  ReasonCodeDescription: string = "";
  ReferenceNumber: string = "";
  ResponseSignature: string = "";
  SyReferenceNumber: string = "";
  SignatureVerified: boolean = false;
  AuthorizationCode: string = "";
  PaddedCardNo: string = "";
  PaidReceiptsIDs: string = "";  
  CreationDate: Date = new Date();
  CreatedBy: number = 0;  
  LastUpdateDate: Date = new Date();
  LastUpdatedby: number = 0;
  PostingStatus: string = "";
  SynthesiDocumentNumber: string = "";
  SySerial: number = 0;
  PostStatusDescription: string = "";
  SyStatus: string = "";
  ProductFormID: number = 0;
}
