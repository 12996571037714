

import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';


@Injectable()
export class CommonService {
  private _Url: string = "/api/common/";

  constructor(private authHttp: HttpClient, private auth: AuthenticationService) {
  }

  //all methods are unique

  getValidationRules() {
    return this.authHttp.get<any>("/api/ValidationRule/Branch/FormValidation" + "/");
  }

  getTableNames() {
    return this.authHttp.get<any>(this._Url + "GetTableNames" + "/");
  }

  getDatabaseDatetime() {
    return this.authHttp.get<any>(this._Url + "GetDatabaseDatetime" + "/");
  }

  GetAllOccupations() {
    return this.authHttp.get<any>(this._Url + "SyGetAllOccupations" + "/");
  }

  GetAllItemsDoctors() {
    return this.authHttp.get<any>(this._Url + "SyGetAllItemsDoctors" + "/");
  }

  GetOccupationsById(id: number) {
    return this.authHttp.get<any>(this._Url + "SyGetOccupationsByCode/" + id + "/");
  }

  GetOccupationsByCode(code: string) {
    return this.authHttp.get<any>(this._Url + "SyGetOccupationsByCode/" + code + "/");
  }

  FilterOccupations(filter: string) {
    return this.authHttp.get<any>(this._Url + "SyFilterOccupations/" + filter + "/");
  }

  //NATIONALITIES
  GetAllNationalities() {
    return this.authHttp.get<any>(this._Url + "SyGetAllNationalities" + "/");
  }

  GetNationalityById(Code: string) {
    return this.authHttp.get<any>(this._Url + "SyGetNationalityByCode/" + Code + "/");
  }

  FilterNationalities(filter: string) {
    return this.authHttp.get<any>(this._Url + "SyFilterNationalities/" + filter + "/");
  }

  //POST CODES
  GetAddressesByPostCode(postCode: string) {
    return this.authHttp.get<any>(this._Url + "GetAddressesByPostalCode/" + postCode + "/");
  }

  //TEMPLATES
  FilterTemplates(SubForm: string, AccountType: string, InsuranceCover: string) {
    return this.authHttp.get<any>(this._Url + "SyFilterTemplates/" + SubForm + "/" + AccountType + "/" + InsuranceCover + "/");
  }

  GetAllBodyTypes() {
    return this.authHttp.get<any>(this._Url + "SyGetAllBodyTypes" + "/");
  }

  GetAllModels() {
    return this.authHttp.get<any>(this._Url + "SyGetAllModels" + "/");
  }

  GetAllMakes() {
    return this.authHttp.get<any>(this._Url + "SyGetAllMakes" + "/");
  }

  //ITEMS
  SyGetVehiclesByRegistrationRTD(reg: string) {
    return this.authHttp.get<any>(this._Url + "SyGetVehiclesByRegistrationRTD/" + reg);
  }

  SyGetVehiclesByChassisRTD(sashi: string) {
    return this.authHttp.get<any>(this._Url + "SyGetVehiclesByChassisRTD/" + sashi + "/");
  }

  SyGetVehiclesByRegistrationSY(reg: string) {
    return this.authHttp.get<any>(this._Url + "SyGetVehiclesByRegistrationSY/" + reg + "/");
  }

  SyGetVehiclesBySashiSY(reg: string) {
    return this.authHttp.get<any>(this._Url + "SyGetVehiclesBySashiSY/" + reg + "/");
  }

  //DRIVERS
  GetDriversByIdentity(identity: string) {
    return this.authHttp.get<any>(this._Url + "SyGetDriverById/" + identity + "/");
  }

  FilterDriverByIdentity(identity: string) {
    return this.authHttp.get<any>(this._Url + "SyGetDriversByIdentity/" + identity + "/");
  }

  GetDriverBySySerial(driverSerial: string) {
    return this.authHttp.get<any>(this._Url + "SyGetDriverBySySerial/" + driverSerial + "/");
  }

  SyGetVehicleWindScreen(registration: string) {
    return this.authHttp.get<any>(this._Url + "SyGetVehicleWindScreen/" + registration + "/");
  }

  SyGetCarAbroad(registration: string) {
    return this.authHttp.get<any>(this._Url + "SyGetCarAbroad/" + registration + "/");
  }

  SyGetRescuelineCover(registration: string) {
    return this.authHttp.get<any>(this._Url + "SyGetRescuelineCover/" + registration + "/");
  }

  SyLifeLine(PolicyNumber: string, FirstName: string, LastName: string, BirthDate: string, PhoneNumber: string, RegistrationNumber: string) {
    return this.authHttp.get<any>(this._Url + "syLifeLine/" + PolicyNumber + "/" + FirstName + "/" + LastName + "/" + BirthDate + "/" + PhoneNumber + "/" + RegistrationNumber + "/");
  }

  SyGetAssistAmericaCover(searchOption: string, searchValue: string) {
    return this.authHttp.get<any>(this._Url + "SyGetAssistAmericaCover/" + searchOption + "/" + searchValue + "/");
  }

  SyGetAgentDetails() {
    return this.authHttp.get<any>(this._Url + "SyGetAgentDetails" + "/");
  }

  SyGetAgentDetailsBySerial(agentSerial: number) {
    return this.authHttp.get<any>(this._Url + "SyGetAgentDetailsBySerial" + "/" + agentSerial + "/");
  }

  getByUserIDMA(userID: number) {
    return this.authHttp.get<any>(this._Url + "GetByUserIDMA" + "/UID" + "/" + userID + "/");
  }
  getByUserIDCNMA(userID: number) {
    return this.authHttp.get<any>(this._Url + "GetByUserIDCNMA" + "/UID" + "/" + userID + "/");
  }

  getSySubAgentCodesFromAgentGroupCode(agentSerial: number ,agentGroupCode: string, subAgentCode: string) {
    return this.authHttp.get<any>(this._Url + "GetSySubAgentCodesFromAgentGroupCode/" + agentSerial + "/" + agentGroupCode + "/" + subAgentCode);
  }

  GetAllSyGarageCodes() {
    return this.authHttp.get<any>(this._Url + "GetAllSyGarageCodes/");
  }

  getAllInvolvedInClaim(claimSerial: string) {
    return this.authHttp.get<any>(this._Url + "GetAllInvolvedInClaim/" + claimSerial + "/");
  }

  getSyBanks() {
    return this.authHttp.get<any>(this._Url + "GetSyBanks/");
  }

  syCheckIBanValid(documentDetails: string, bankCode: string, iBan: string) {
    return this.authHttp.get<any>(this._Url + "SyCheckIBanValid/" + documentDetails + "/" + bankCode + "/" + iBan);
  }

}
