//import { NgModule } from '@angular/core';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule, MatDialogModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser'
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { MatTabsModule } from '@angular/material';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MaterialModule } from '../../modules/material.module';

import { PdfViewerModule, PdfViewerComponent, PDFDocumentProxy, PDFSource } from 'ng2-pdf-viewer';
import { HelpPDFCustomViewerComponent} from '../../components-global/help-pdf-custom-viewer/helppdfcustomviewer.component';


@NgModule({
  imports: [PdfViewerModule,RouterModule, CommonModule, NgbModule, MaterialModule, FormsModule, ReactiveFormsModule, MatButtonModule, MatDialogModule, BrowserModule, FormsModule, MatTabsModule ],
  declarations: [ConfirmDialogComponent, HelpPDFCustomViewerComponent],
  entryComponents: [ConfirmDialogComponent],
  exports: [PdfViewerModule,MatButtonModule, MatDialogModule, ConfirmDialogComponent]
})
export class CustomMaterialModule {
}
