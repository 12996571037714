import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt'
import { Observable } from 'rxjs';

import { AuthenticationService, UserInfoService } from '../_services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private userInfoService: UserInfoService,
    private jwtHelper: JwtHelperService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const currentUser = this.authenticationService.currentUserValue;
    try {
      return new Promise((resolve, reject) => {
        this.userInfoService.setLoggedInUser().toPromise().then((response) => {


          if (!currentUser) {
            //not logged in so return false      
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            resolve(false);
          }


          const token = currentUser.accessToken;


          if (this.authenticationService.isTokenExpired) {
            //console.log('Log out from auth.guard.ts');
            this.authenticationService.logout();
            resolve(false);
            //this.authenticationService.renew().subscribe();   //CHARIS CHANGE
          }

          var tokenPayload = this.jwtHelper.decodeToken(token);


          let userroles = tokenPayload["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

          let roleExists = false;
          
          // ADMINISTRATOR USERS HAS FULL ACCESS
          if (userroles) {          
            if (!roleExists) {
              roleExists = (userroles.indexOf('Administrator') > -1);
            }            
            //Check if user has the role access that the module has in the routing
            if (!roleExists) {              
              if (Array.isArray(userroles)) {
                userroles.forEach((userRole) => {              
                  route.data.roles.forEach((moduleRole) => {              
                    if (userRole === moduleRole) {
                      roleExists = true;

                    }
                  });
                });
              }              
            }            
          }          

          // Check if the user has access to the page through ModulesAccess      
          if (!roleExists) {
            if (this.userInfoService.allowNavigate(route.routeConfig.path.toUpperCase())) {
              roleExists = true;
            }
          }


          if (!roleExists) {
            this.router.navigate(['/']);
          }
          resolve(roleExists);
          //return roleExists;

        });
      });
    } catch (Error) {
      //console.log('AuthguardError');
      //console.log(Error);
      console.log('Log out from catch on auth.guard.ts, error: ',Error);
      this.authenticationService.logout();
      return false;
    }
  }
}
