import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';

export class ConfirmDialogModel {

  public title: string;
  public message: string;
  public type: string;

  constructor(public atitle: string, public amessage: string, public aType?: string) {

    this.title = atitle;
    this.message = amessage;
    this.type = aType;
  }
}


@Component({
  moduleId: module.id,
  selector: 'confirm-dialog',
  templateUrl: 'confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css']
})
export class ConfirmDialogComponent implements OnInit {
  title: string;
  message: string;
  type: string;
  videoSource: string;

  element: HTMLElement;

  pdfViewSource: string = "../../../assets/pdf/Extranet User Manual.pdf";

  @ViewChild("videoPlayer", { static: false }) videoplayer: ElementRef;
  isPlay: boolean = false;

  toggleVideo(event: any) {

    if (this.videoplayer.nativeElement.paused)
    {
      this.videoplayer.nativeElement.play();
    }
    else {
      this.videoplayer.nativeElement.pause();
    }
  }

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.type = data.type;

    if (this.type == undefined) {
      this.type = '';
    }
  }


  proceedClick(type) {
    this.dialogRef.close(type);

  }

  responseClick(type) {
    this.dialogRef.close(type);

  }

  ngOnInit() {
  }

  //onConfirm(): void {
  //  // Close the dialog, return true
  //  this.dialogRef.close(true);
  //}

  //onDismiss(): void {
  //  // Close the dialog, return false
  //  this.dialogRef.close(false);
  //}

  videoGoTo(sec: number) {

    this.videoplayer.nativeElement.currentTime = sec;
    this.videoplayer.nativeElement.play();
}
}
/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
