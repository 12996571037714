import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthenticationService, UserInfoService } from '../_services';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../_services/authentication.service";
import * as i3 from "../_services/userInfo.service";
import * as i4 from "@auth0/angular-jwt/src/jwthelper.service";
export class AuthGuard {
    constructor(router, authenticationService, userInfoService, jwtHelper) {
        this.router = router;
        this.authenticationService = authenticationService;
        this.userInfoService = userInfoService;
        this.jwtHelper = jwtHelper;
    }
    canActivate(route, state) {
        const currentUser = this.authenticationService.currentUserValue;
        try {
            return new Promise((resolve, reject) => {
                this.userInfoService.setLoggedInUser().toPromise().then((response) => {
                    if (!currentUser) {
                        //not logged in so return false      
                        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
                        resolve(false);
                    }
                    const token = currentUser.accessToken;
                    if (this.authenticationService.isTokenExpired) {
                        //console.log('Log out from auth.guard.ts');
                        this.authenticationService.logout();
                        resolve(false);
                        //this.authenticationService.renew().subscribe();   //CHARIS CHANGE
                    }
                    var tokenPayload = this.jwtHelper.decodeToken(token);
                    let userroles = tokenPayload["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
                    let roleExists = false;
                    // ADMINISTRATOR USERS HAS FULL ACCESS
                    if (userroles) {
                        if (!roleExists) {
                            roleExists = (userroles.indexOf('Administrator') > -1);
                        }
                        //Check if user has the role access that the module has in the routing
                        if (!roleExists) {
                            if (Array.isArray(userroles)) {
                                userroles.forEach((userRole) => {
                                    route.data.roles.forEach((moduleRole) => {
                                        if (userRole === moduleRole) {
                                            roleExists = true;
                                        }
                                    });
                                });
                            }
                        }
                    }
                    // Check if the user has access to the page through ModulesAccess      
                    if (!roleExists) {
                        if (this.userInfoService.allowNavigate(route.routeConfig.path.toUpperCase())) {
                            roleExists = true;
                        }
                    }
                    if (!roleExists) {
                        this.router.navigate(['/']);
                    }
                    resolve(roleExists);
                    //return roleExists;
                });
            });
        }
        catch (Error) {
            //console.log('AuthguardError');
            //console.log(Error);
            console.log('Log out from catch on auth.guard.ts, error: ', Error);
            this.authenticationService.logout();
            return false;
        }
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.AuthenticationService), i0.ɵɵinject(i3.UserInfoService), i0.ɵɵinject(i4.JwtHelperService)); }, token: AuthGuard, providedIn: "root" });
