<meta http-equiv="Content-Type" content="text/html; charset=utf-8">


<h1 mat-dialog-title>
  {{title}}
</h1>
<div mat-dialog-content style="overflow:hidden">
  <div *ngIf="message==''">

    <mat-tab-group>

      <!--<mat-tab label="Help Video">

        <div class="row" style="width: 100%;">
          <div class="col-md-9">
            <div class="video">
              <video controls #videoPlayer style="width:100%; height:500px;">
                <source src="../../../assets/videos/extranet_new_V7.mp4" type="video/mp4" />
                Browser not supported
              </video>
            </div>
          </div>
          <div class="col-md-2" style="height:500px;">
            <h5 style="height:40px; margin:0">Go to</h5>
            <ul style="border:1px solid gray; background-color:#f4f3ef; padding:1px; height:460px; overflow-y:auto" class="navbar-nav flex-grow">
              <li class="li-list-help"><button class="btn btn-primary btn-list-help" (click)="videoGoTo(10)"> Dashboard</button></li>
              <li class="li-list-help"><button class="btn btn-primary btn-list-help" (click)="videoGoTo(50)"> Receipts List</button></li>
              <li class="li-list-help"><button class="btn btn-primary btn-list-help" (click)="videoGoTo(140)"> Create New Receipt</button></li>
              <li class="li-list-help"><button class="btn btn-primary btn-list-help" (click)="videoGoTo(150)"> Receipts : Create New Client</button></li>
              <li class="li-list-help"><button class="btn btn-primary btn-list-help" (click)="videoGoTo(171)"> Receipts : Add Existing Client</button></li>
              <li class="li-list-help"><button class="btn btn-primary btn-list-help" (click)="videoGoTo(185)"> Create New Receipt After Client</button></li>
              <li class="li-list-help"><button class="btn btn-primary btn-list-help" (click)="videoGoTo(288)"> Receipt Post</button></li>
              <li class="li-list-help"><button class="btn btn-primary btn-list-help" (click)="videoGoTo(326)"> Clients</button></li>
              <li class="li-list-help"><button class="btn btn-primary btn-list-help" (click)="videoGoTo(377)"> Client Statement</button></li>
              <li class="li-list-help"><button class="btn btn-primary btn-list-help" (click)="videoGoTo(400)"> Receipts Not Deposited</button></li>
              <li class="li-list-help"><button class="btn btn-primary btn-list-help" (click)="videoGoTo(450)"> Outstanding Installments</button></li>
              <li class="li-list-help"><button class="btn btn-primary btn-list-help" (click)="videoGoTo(492)"> Vehicle Details</button></li>
              <li class="li-list-help"><button class="btn btn-primary btn-list-help" (click)="videoGoTo(504)"> JCC - Deposits</button></li>
              <li class="li-list-help"><button class="btn btn-primary btn-list-help" (click)="videoGoTo(525)"> Questions</button></li>
            </ul>
          </div>

        </div>

      </mat-tab>-->
      <mat-tab label="User Manual">

        <helppdfcustomviewer [pdfDisplay]="pdfViewSource" style="overflow:auto;"></helppdfcustomviewer>

      </mat-tab>
      <mat-tab label="Bank Information">
        <div class="row" style="overflow-y:auto;height:518px;width: 100%;">

          <!--table results-->

          <div class="col-md-4 bank-container-custom">
            <div style="font-weight:bold">ALPHA BANK</div>
            <div>A/C 435-101-000051-9</div>
            <div>IBAN: CY72009004340004341010000519</div>
            <div>SWIFT CODE: ABKLCY2N</div>
          </div>

          <div class="col-md-4 bank-container-custom">
            <div style="font-weight:bold">ΕΘΝΙΚΗ ΤΡΑΠΕΖΑ</div>
            <div>A/C 100002452424</div>
            <div>IBAN: CY90006005350000005355172935</div>
            <div>SWIFT: ETHNCY2NXXX</div>
          </div>

          <div class="col-md-4 bank-container-custom">
            <div style="font-weight:bold">ΕΛΛΗΝΙΚΗ ΤΡΑΠΕΖΑ</div>
            <div>A/C 314-01-281276-01</div>
            <div>IBAN: CY44005003140003140128127601</div>
            <div>SWIFT: HEBACY2N</div>
          </div>

          <div class="col-md-4 bank-container-custom">
            <div style="font-weight:bold">ΟΡΓΑΝΙΣΜΟΣ ΧΡΗΜ.ΣΤΕΓΗΣ</div>
            <div>A/C 4052807-1</div>
            <div>IBAN: CY36 0210 0000 0000 4052 8071</div>
            <div>SWIFT: HFICCY2N</div>
          </div>

          <div class="col-md-4 bank-container-custom">
            <div style="font-weight:bold">ΤΡΑΠΕΖΑ ΚΥΠΡΟΥ</div>
            <div>A/C 055001069852</div>
            <div>IBAN: CY13002005500000000106985200</div>
            <div>SWIFT: BCYPCY2N</div>
          </div>

          <div class="col-md-4 bank-container-custom">
            <div style="font-weight:bold">ANCORIA BANK</div>
            <div>A/C 0012538101013</div>
            <div>IBAN: CY22029001010000012538101013</div>
            <div>SWIFT: ANCOCY2N</div>
          </div>



          <div class="col-md-4 bank-container-custom">
            <div style="font-weight:bold">ASTROBANK</div>
            <div>A/C 1921877</div>
            <div>IBAN: CY96008003020000000001921877</div>
            <div>SWIFT: PIRBCY2N</div>
          </div>

          <div class="col-md-4 bank-container-custom">
            <div style="font-weight:bold">CDB BANK</div>
            <div>A/C 101010016110016</div>
            <div>IBAN: CY49 0140 0101 0101 0100 1611 0016</div>
            <div>SWIFT BIC: CYDBCY2N</div>
          </div>

          <div class="col-md-4 bank-container-custom">
            <div style="font-weight:bold">EURO BANK</div>
            <div>A/C 004-2001-00237511</div>
            <div>IBAN: CY63018000040000200100237511</div>
            <div>SWIFT: ERBKCY2N</div>
          </div>

          <div class="col-md-4 bank-container-custom">
            <div style="font-weight:bold">SOCIETE GENERALE</div>
            <div>A/C 024-001-450-020022-01-2</div>
            <div>CY81012000024001450020022012</div>
            <div>SWIFT SOGECY2N</div>
          </div>

          <div class="col-md-4 bank-container-custom">
            <div style="font-weight:bold">ARAB JORDAN</div>
            <div>A/C 501/0082272/037/0565/000</div>
            <div>IBAN: CY41102000010082272370565000</div>
            <div>SWIFT: AJIBCY21</div>
          </div>
        </div>



      </mat-tab>



    </mat-tab-group>


  </div>

  <div [innerHTML]="message"></div>
  <!--{{message}}-->
</div>

<div mat-dialog-actions align="center" *ngIf="type!='' && type == 'EmailOrDownload'">
  <button mat-button cdkFocusInitial (click)="proceedClick('Download')">Download</button>
  <button mat-button cdkFocusInitial (click)="proceedClick('Email')">Send by Email</button>
  <button mat-button mat-dialog-close>Close</button>
</div>
<div mat-dialog-actions align="center" *ngIf="type!='' && type == 'InfoPopUp'">
  <button mat-button mat-dialog-close>Close</button>
</div>
<div mat-dialog-actions align="center" *ngIf="type!='' && type == 'products'">
  <button mat-button cdkFocusInitial (click)="proceedClick('Proposal')">Proposal</button>
  <button mat-button cdkFocusInitial (click)="proceedClick('Quotation')">Quotation</button>
  <button mat-button mat-dialog-close>Close</button>
</div>
<div mat-dialog-actions align="center" *ngIf="message!='' && type==''">
  <button mat-button cdkFocusInitial [mat-dialog-close]="true">Yes</button>
  <button mat-button mat-dialog-close>No</button>
</div>
<div mat-dialog-actions align="center" *ngIf="type!='' && type == 'language'">
  <button mat-button cdkFocusInitial (click)="proceedClick('gr')">Greek</button>
  <button mat-button cdkFocusInitial (click)="proceedClick('en')">English</button>
  <button mat-button mat-dialog-close>Close</button>
</div>
<div mat-dialog-actions align="center" *ngIf="message=='' && type==''">
  <button mat-button mat-dialog-close>Close</button>
</div>
