
import { PoliciesObject } from '../_models/policy';
import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service'
import { HttpClient } from '@angular/common/http';
import { concat, map, switchMap, filter, tap } from 'rxjs/operators';
import { Observable, Subscription, EMPTY, empty } from 'rxjs';
import { SyClientsService, ClientService, NotificationsService, ToasterType, ToasterPosition } from '.';
import { ClientObject } from '../_models/client';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';


@Injectable()
export class PolicyService {
  private _Url: string = "/api/policy/";
  private _genericUrl: string = "/api/generic/";
  private _controllerName: string = "policy";

  //requirements for the loading bar in clients
  public progressbarValue = 0;
  public progressbarMaxSteps = 4;
  public progressbarStep = 0;

  constructor(private authHttp: HttpClient,
    private auth: AuthenticationService,
    private syClientsService: SyClientsService,
    private clientService: ClientService,
    private notificationService: NotificationsService
  ) {

  }

  getAll() {
    return this.authHttp.get<any>(this._Url + "/");
  }

  getByID(aPolicyID: number) {
    return this.authHttp.get<any>(this._Url + "GetByID/" + aPolicyID + "/");
  }


  add(aPolicy: PoliciesObject) {
    return this.authHttp.post<any>(this._Url + "Modify" + "/I" + "/", aPolicy);
  }

  update(aPolicyID: number, aPolicy: PoliciesObject) {
    return this.authHttp.post<any>(this._Url + "Modify" + "/U/" + aPolicyID + "/", aPolicy);
  }

  delete(aPolicyID: number) {
    let aPolicy: PoliciesObject = new PoliciesObject();
    return this.authHttp.post<any>(this._Url + "Modify" + "/D/" + aPolicyID + "/", aPolicy)
  }


  getNextPolicySerial() {
    return this.authHttp.get<any>(this._Url + "GetNextPolicySerial/" + "/");
  }


  insertUpdateClientFromSybaseModel(formClient: ClientObject) {

    //first retrieve the client from sybase.

    var syClient;
    var localClient;
    var updatedLocalClient: ClientObject;
    var returnClientId = 0;
    var localClientRes;

    this.progressbarValue = 0;
    this.progressbarStep = 0;

    return this.clientService.getByIdentity(formClient.identityCard)
      .pipe(
        switchMap(r1 => {

          localClientRes = r1[0];
          this.progressbarValue = 25;
          this.progressbarStep = 1;

          if (localClientRes) {
            //update local entry
            return this.clientService.update(localClientRes.ClientID, formClient).pipe(map(res1 => { return res1[0]; }));
          }
          else {
            return this.clientService.add(formClient).pipe(map(res1 => {

              return res1[0];
            }));
          }


        })
        ,
        switchMap(localClientRes => {

          this.progressbarValue = 50;
          this.progressbarStep = 2;

          //setup the addresses
          localClientRes['Country'] = formClient['country'];
          localClientRes['Country_alt'] = formClient['country_alt'];
          localClientRes['District'] = formClient['district'];
          localClientRes['District_alt'] = formClient['district_alt'];
          localClientRes['AddressLine1'] = formClient['addressLine1'];
          localClientRes['AddressLine1_alt'] = formClient['addressLine1_alt'];
          localClientRes['AddressLine2'] = formClient['addressLine2'];
          localClientRes['AddressLine2_alt'] = formClient['addressLine2_alt'];
          localClientRes['City'] = formClient['city'];
          localClientRes['City_alt'] = formClient['city_alt'];
          localClientRes['MailCity'] = formClient['mailCity'];
          localClientRes['MailCity_alt'] = formClient['mailCity_alt'];
          localClientRes['MailCountry'] = formClient['mailCountry'];
          localClientRes['MailCountry_alt'] = formClient['mailCountry_alt'];
          localClientRes['MailDistrict'] = formClient['mailDistrict'];
          localClientRes['MailDistrict_alt'] = formClient['mailDistrict_alt'];
          localClientRes['MailAddressLine1'] = formClient['mailAddressLine1'];
          localClientRes['MailAddressLine1_alt'] = formClient['mailAddressLine1_alt'];
          localClientRes['MailAddressLine2'] = formClient['mailAddressLine2'];
          localClientRes['MailAddressLine2_alt'] = formClient['mailAddressLine2_alt'];
          localClientRes['StreetNo_alt'] = formClient['streetNo_alt'];
          localClientRes['SubAgentCode'] = formClient['subAgentCode'];


          return this.syClientsService.exportClientToSy(localClientRes).pipe(map(syClientRes => ({ syClientRes, localClientRes })
          ));
        }),
        switchMap(r3 => {


          const localClientFound = r3["localClientRes"];
          const syClientFound = r3["syClientRes"];

          //localClientFound["AgentSySerial"] = localClientFound["AgentSySerial"];
          localClientFound["GeneralAgentSySerial"] = syClientFound["generalAgentSySerial"];
          localClientFound["SyClientCode"] = syClientFound["syClientCode"];
          localClientFound["SyClientSerial"] = syClientFound["syClientSerial"];
          localClientFound["AccountCode"] = syClientFound["accountCode"];

          this.progressbarValue = 75;
          this.progressbarStep = 3;
          return this.clientService.update(localClientFound["ClientID"], localClientFound).pipe(map(res => {

            return res;
          }));
        })
      );

    ////update or add in synthesis
    //switchMap(res1 => {
    //  this.progressbarValue = 50;
    //  this.progressbarStep = 2;
    //  return this.syClientsService.exportClientToSy(res1[0]);
    //}),
    //  //update local entry with synthesis data
    //  switchMap(res2 => {
    //    this.progressbarValue = 75;
    //    this.progressbarStep = 3;
    //    return this.clientService.update(localClientRes.ClientID, res2);
    //  }))



  }

  insertUpdateClientFromSybase(syClientSerial: string) {

    //first retrieve the client from sybase.

    var syClient;
    var localClient;
    var updatedLocalClient: ClientObject;
    var returnClientId = 0;

    return this.syClientsService.GetClientBySySerial(syClientSerial).pipe(
      switchMap(r1 => {

        //if there is no client found the most likely case is that the status was changed from N
        if (r1 == undefined || r1 == null ) {

          this.notificationService.show(ToasterType.error, "There was an issue retrieving the client, please contact the underwriting department", ToasterPosition.topCenter, 4000);

          return;
        }

        const syClientRes = r1[0];
     
        if (syClientRes) {
        
          return this.clientService.getByIdentity(syClientRes["IdentityCard"]).pipe(map(localClientRes => ({ syClientRes, localClientRes })))
        } else {
          this.notificationService.show(ToasterType.error, "There was an issue retrieving the client, please contact the underwriting department", ToasterPosition.topCenter, 4000);
          return EMPTY;
        }
      }), switchMap(r2 => {


        const localClientFound = r2["localClientRes"][0];
        const syClientFound = r2["syClientRes"];

        //return localClientFound;

        const latestClientVersion = this.getClientModelFromSyClientModel(syClientFound);

        if (localClientFound) {
   
          latestClientVersion.createdBy = localClientFound.CreatedBy;
          latestClientVersion.creationDate = localClientFound.CreationDate;
          latestClientVersion.clientID = localClientFound.ClientID;

          //return localClientFound;
          return this.clientService.update(localClientFound.ClientID, latestClientVersion).pipe(map(res => {
     
            return localClientFound['ClientID'];
            //return res[0].ClientID;
          }
            , error => {
         
              return localClientFound['ClientID'];;

            }
          )

          )
        }
        else {
     
          latestClientVersion.agentCode = syClientFound["AgentCode"];
          return this.clientService.add(latestClientVersion).pipe(map(res => {

            return res[0].ClientID;
          }));
        }
        return EMPTY;
      })

    );
  }


  public getClientModelFromSyClientModel(syClientModel: Array<any>) {

    var ret: ClientObject = new ClientObject();

    for (let field in syClientModel) {
      //check if the field exists in the model      
      if (Object.keys(ret).findIndex(v => v.toUpperCase() == field.toUpperCase()) > -1) {

        ret[field.charAt(0).toLowerCase() + field.slice(1)] = syClientModel[field];
      }
      else {

      }
    }


    return ret;
  }



}
